<template>
  <div class="sg-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <el-button style="float: right; padding: 3px 0" type="text"
          >个人信息</el-button
        >
      </div>
      <el-form
        ref="createEditForm"
        :model="item"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item prop="name" label="用户名">
          <el-input
            v-model="item.name"
            placeholder="请输入用户名"
            class="sg-login-input"
          ></el-input>
        </el-form-item>
        <el-form-item prop="images" label="头像">
          <sg-upload v-model="item.avatar" :multiple="false"></sg-upload>
        </el-form-item>
        <el-form-item prop="email" label="邮箱">
          <el-input
            v-model="item.email"
            placeholder="请输入邮箱"
            class="sg-login-input"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机">
          <el-input
            v-model="item.phone"
            placeholder="请输入手机"
            class="sg-login-input"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            v-model="item.password"
            placeholder="请输入密码，不修改请留空"
            class="sg-login-input"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password_confirm" label="重复密码">
          <el-input
            v-model="item.password_confirm"
            placeholder="请重复输入密码"
            class="sg-login-input"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapState } from "vuex"
import { validatePhone } from "@/services/validator.js";

export default {
  name: "Profile",
  data() {
    // var validatePassword = (rule, value, callback) => {
    //   var len = value.length;
    //   if (this.action == "create") {
    //     if (!len) callback(new Error("密码必填"));
    //     else if (len < 6) callback(new Error("密码最少6位"));
    //   }
    //   callback();
    // };
    var validateConfirm = (rule, value, callback) => {
      if (value != this.item.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      title: "个人信息",
      pre_uri: "admin-users/",
      // create/update
      action: "create",
      emptyItem: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirm: "",
        // http://app.vuee/storage/uploadhttps://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/20201225/5fe60225d098b.png
        avatar: "",
      },
      item: {},
      searchItem: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" },
        ],
        // password: [{ validator: validatePassword, trigger: "blur" }],
        password_confirm: [{ validator: validateConfirm, trigger: "blur" }],
      },
    };
  },
  computed: {},
  mounted() {
    this.getMe();
  },
  methods: {
    getMe() {
      this.$util.getData(this, "admin-users/me", {}, "item");
    },
    submit() {
      this.$refs["createEditForm"].validate((valid) => {
        if (valid) {
          this.submitForm();
          return true;
        } else {
          return false;
        }
      });
    },
    submitForm() {
      var url = this.pre_uri + 'update';
      this.$http.post(url, this.item).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
      });
    },
  },
};
</script>
